/* Font declaration */
@font-face {
  font-family: Ciutadella-Slab;
  src: url('./assets/fonts/ciutadella-slab-rg.eot');
  src: url('./assets/fonts/ciutadella-slab-rg.eot?') format('☺'),
       url('./assets/fonts/ciutadella-slab-rg.woff') format('woff'),
       url('./assets/fonts/ciutadella-slab-rg.woff2') format('woff2'),
       url('./assets/fonts/ciutadella-slab-rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Ciutadella-Regular;
  src: url('./assets/fonts/ciutadella-regular.eot');
  src: url('./assets/fonts/ciutadella-regular.eot?') format('☺'),
       url('./assets/fonts/ciutadella-regular.woff') format('woff'),
       url('./assets/fonts/ciutadella-regular.woff2') format('woff2'),
       url('./assets/fonts/ciutadella-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* Base */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Text / Fonts / Headings */
div, p, span, b, a, label, input, text select, table td, table th, ul li,
area, button, [role='button'], input:not([type='range']), summary, textarea,
.ant-form-item .ant-mentions, .ant-form-item textarea.ant-input,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, 
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content,
.ant-badge-status-text, .ant-col, .ant-form-item-label>label {
    font-family: "Ciutadella-Regular", Tahoma, sans-serif;
    font-size: 18px;
}

h2 {
  font-family: "Ciutadella-Slab", Georgia, serif;
  font-weight: 700;
  font-size: 32px;
  color: #929a4e;
}

h3 {
  font-family: "Ciutadella-Regular", Tahoma, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.3px;
}

/* Elements - Buttons/Inputs | Formelements */

.ant-input:hover {
  border-color: #929a4e;
}

.ant-input:focus, .ant-input-focused {
  border-color: #929a4e;
  box-shadow: 0 0 0 2px rgba(146, 154, 78, 0.2);
}

.ant-btn-primary {
  border: 1px solid #929a4e;
  background-color: #929a4e;
  height: auto;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  border: 1px solid #72783d;
  background-color: #72783d;
}

.ant-radio-inner{
  border-color: #929a4e;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #929a4e;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 2px rgba(146, 154, 78, 0.2);
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: #929a4e;
}

.ant-radio-inner::after {
  background-color: #929a4e;
}

.ant-picker-focused {
  box-shadow: 0 0 0 2px rgba(146, 154, 78, 0.2);
  border-color: #929a4e;
}

.ant-picker-input > input:hover {
  border-color: #929a4e;
}

.ant-picker-today-btn{
  color: #929a4e;
}

.ant-picker-today-btn:hover {
  color: #72783d;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #929a4e;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #929a4e;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-checked::after {
  border-color: #929a4e;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #929a4e;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
  background: #929a4e;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #929a4e;
}

.ant-tabs-ink-bar {
  background: #929a4e;
}

.ant-badge-status-processing {
  background-color: #929a4e;
}

.ant-badge-status-processing::after {
  border-color: #929a4e;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #929a4e;
  box-shadow: 0 0 0 2px rgba(146, 154, 78, 0.2);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #929a4e;
}

.ant-select-open, .ant-select-focused {
  border-color: #929a4e;
  box-shadow: 0 0 0 2px rgba(146, 154, 78, 0.2);
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #929a4e;
}

